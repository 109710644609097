import React, {useState} from "react"
import Footer from '../components/footer'
import BtnUp from '../components/btn_up'
import NavBar from '../components/nav-bar'
import { Body } from '../components/common'
import Nldetail from '../components/nl_detail'
import ConsulSign from '../components/consul_sign'

import { getMenuItem, setMenuItem } from "../cookies/submenu_cookie"
// import scrollTo from 'gatsby-plugin-smoothscroll'
import { setShowNL } from "../cookies/nl_sign_cookie"
import { navigate } from "gatsby"
import { graphql } from "gatsby"

let content = {}
let pictures = []
// let nl = {}

export default ({ data }) =>{ 
    
    const [consultation, setConsultation] = useState(false);

    const openConsulSign = (evt) =>{
        setConsultation(true)
    }

    setShowNL({'show': false})
    
    // console.log('graphql data: ', data)
    pictures = data.images.edges
    // console.log('pictures: ', pictures)
    // content = JSON.parse(JSON.parse(data.content).content)
    content = JSON.parse(JSON.parse(data.content.internal.content).content)
    // nl = data.content.internal.content
    // console.log('nl content: ', content)


    
    const changeService = (evt) =>{
        // console.log('option: ', evt.target.id)
        var menu_temp = getMenuItem()

        if(evt.target.id === 'true'){
            // scrollTo('#is')
            // setMenuItem({'item': evt.target.name})
            menu_temp['item'] = evt.target.name
            setMenuItem(menu_temp)
            navigate('/#is')
            // setService(evt.target.name)
        }else{
            navigate('/per_diem')
        }
        // else{
        //     // scrollTo('#per_diem')
        //     menu_temp['item2'] = parseInt(evt.target.name) - 1
        //     setMenuItem(menu_temp)
        //     navigate('/#per_diem')
        //     // setPerDiem(parseInt(evt.target.name) - 1)
        // }
    }
    return(
        <Body>
            <NavBar pickService={changeService} iService={0} perDiem={0} abs={true} isSticky={false} consulOpen={openConsulSign}/>
            <NavBar pickService={changeService} iService={0} perDiem={0} isSticky={true} consulOpen={openConsulSign}/>
            <div style={{height:'80px'}}></div>
            <ConsulSign open={consultation} onClose={()=>setConsultation(false)}/>
            <Nldetail nl_content={content} pictures={pictures}/>
            <Footer/>
            <BtnUp/>
        </Body>
    )
}

export const query = graphql`
  query($img_arr: [String], $id: String) {
    images: allImage(filter: {id: {in: $img_arr}}) {
        edges {
            node {
                id
                image {
                childImageSharp {
                    fluid(maxWidth: 3600) {
                        ...GatsbyImageSharpFluid
                    }
                }
                }
            }
        }
    }
   content: newsletter(id: {eq: $id}) {
    internal {
        content
    }
    id
    }
  }
`

    