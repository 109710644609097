import React, {useState} from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import { useImagesNewsletter } from "../hooks/use-images-newsletter"
import Img from "gatsby-image"
// import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineRight, AiOutlineLeft } from 'react-icons/ai';
import { navigate } from "gatsby"
import { Actionbutton } from './common'





const Bg = styled.div`
   width: 100%;
   h1{
       text-align: center;
   }
   p{
       color: #888;
       text-align: center;
   }
   margin-bottom: 4rem;
`

const MainInfo = styled.div`
   background-color: #f6f6f6;
   color: #888;
   border: 1px solid #aaa;
   border-right-style: none;
   border-left-style: none;
   width: 85%;
   margin: auto;
   overflow: auto;

   font-size: 1.6rem;
   padding: 0.8rem 1.6rem;

   @media (max-width: 600px) {
        width: 95%;                                    
    }

`
const NLcontainer= styled.div`
   width: 85%;
   padding: 0rem 3.2rem;
   margin: auto;
   border: 1px solid #aaa;
   border-right-style: none;
   border-left-style: none;
   border-top-style: none;
   margin-bottom: 40px;

   @media (max-width: 600px) {
        padding: 0rem 0rem;
        width: 90%;                                    
    }
`

const NLcontent= styled.div`
    width: 100%;
    margin-bottom: 4rem;
    h1{
        text-align: left;
        color: #333;
    }
    h2{
        text-align: left;
        color: #333;
    }
    p{
        color: #666;
        text-align: left;
        font-size: 1.4rem;
        margin-bottom: 0;
    }
    span{
        color: #666;
        font-size: 1.4rem;
    }
`

const Header = styled.h1`
  padding: 0.3rem;
  padding-left: 0.1rem;
  font-size: 1.5em;
  width: 90%;
  margin-bottom: 0;
  margin-top: 0;
`

const Label = styled.span`
  padding: 0.3rem;
  padding-left: 0.1rem;
`

const HighlightParagraph = styled.p`
  padding: 0.3rem;
  padding-left: 0.1rem;
  width: 90%;
  text-align: left;
`

const HighlightHeader = styled.h2`
  padding: 0.3rem;
  padding-left: 0.1rem;
`

const FrameContainer = styled.div`
    border: none;
    margin-top: 10px;
    margin-bottom: 10px;
    overflow: auto;
    position: relative;

    span{
        color: #666;
    }
`

const imgFrameSelector = {
    'panoramic': function (img, arg2) {
        return(
            <FrameContainer style={{width:'100%', margin: 'auto'}}>
                 <div css={css`
                    height: 32vw;
                    @media (max-width: 600px) {
                        height: 36vw;                                           
                    }
                    `} style={{width:'100%', position: 'relative', overflow: 'hidden'}}>
                    <Img style={{width:'100%'}} fluid={img.image.childImageSharp.fluid}/>
                </div>
                <div style={{paddingLeft:'10px'}}>
                    <span>{arg2}</span>
                </div>
            </FrameContainer>
        )
    },
    'middle': function (img, arg2) {
        return(
            <FrameContainer style={{width:'80%', margin: 'auto'}}>
                 <div css={css`
                    height: 40vw;
                    @media (max-width: 600px) {
                        height: 45vw;                                           
                    }
                    `} style={{width:'100%', overflow: 'hidden'}}>
                    <Img style={{width:'100%'}} fluid={img.image.childImageSharp.fluid}/>
                </div>
                <div style={{paddingLeft:'10px'}}>
                    <span>{arg2}</span>
                </div>
            </FrameContainer>
        )
    },
    'intext': function (img, arg2) {
        return(
            <FrameContainer style={{width:'50%', margin: '0'}}>
                <div css={css`
                    height: 25vw;
                    @media (max-width: 600px) {
                        height: 28vw;                                           
                    }
                    `} style={{width:'100%', position: 'relative', overflow: 'hidden'}}>
                    <Img style={{width:'100%'}} fluid={img.image.childImageSharp.fluid}/>
                </div>
                <div style={{paddingLeft:'10px'}}>
                    <span>{arg2}</span>
                </div>
            </FrameContainer>
        )
    },
    'portrait': function (img, arg2) {
        return(
            <FrameContainer style={{width:'50%', margin: '0'}}>
                <div css={css`
                    height: 67vw;
                    @media (max-width: 600px) {
                        height: 70vw;                                           
                    }
                    `} style={{width:'100%', position: 'relative', overflow: 'hidden'}}>
                    <Img style={{width:'100%'}} fluid={img.image.childImageSharp.fluid}/>
                </div>
                <div style={{paddingLeft:'10px'}}>
                    <span>{arg2}</span>
                </div>
            </FrameContainer>
        )
    },
}


const componentSelectorDisplay = {
    'title': function (arg1, arg2) {
        return(
            <div style={{marginBottom:'2rem'}}>
                <Header style={{textAlign:'left'}}>{arg1}</Header>
                <Label>{arg2}</Label>
            </div>
        )
    },
    'highlight': function (arg1, arg2) {
        return(
            <div style={{marginBottom:'2rem'}}>
                <HighlightHeader>{arg1}</HighlightHeader>
                <HighlightParagraph style={{textAlign:'left'}}>{arg2}</HighlightParagraph>
            </div>
        )
    },
    'picture': function (arg1, arg2, arg3, img_arr) {
        var img
        for(var i=0; i<img_arr.length; i++){
            if(img_arr[i]['node']['id'] == arg2){
                img = img_arr[i]['node']
            }
        }
        // console.log(img)
        // console.log('arg3: ', arg3)
        return(
            <div style={{marginBottom:'2rem'}}>
                {imgFrameSelector[arg3](img, arg1)}
            </div>
        )
    },
}


let Images =  {}


const NlDetail = (props) =>{
    const [rendering, setRendering] = useState(false);

    Images = useImagesNewsletter()


    return(
    
        <Bg>
           {/* <h1>Newsletters</h1> */}
           {/* <p>Here is the record of all previously sent newsletters</p> */}
           <div style={{height:'50px'}}></div>
            <div css={css`
                @media (max-width: 600px) {
                    height: 2rem;                                     
                }
                `}/>
            <MainInfo>
                <span>{props.nl_content[0].arg1}</span>
                <span style={{float:'right'}}>{props.nl_content[0].arg2}</span>
            </MainInfo>
            <NLcontainer>
                <NLcontent>
                    {props.nl_content.map((element, index) => (
                        <div style={{marginBottom:'3rem'}} key={index}>
                            {componentSelectorDisplay[element.name](element.arg1, element.arg2, element.arg3, props.pictures)}
                        </div>
                    ))}
                </NLcontent>
            </NLcontainer>
            <Actionbutton onClick={()=>navigate('/newsletter')} style={{margin:'auto'}}>Go to List</Actionbutton>
        </Bg>   
       
    )
}

export default NlDetail