import { useStaticQuery, graphql } from "gatsby"
export const useImagesNewsletter = () => {
  const allImages = useStaticQuery(
    graphql`
      query imagesNewsletter {
        liberty_statue: file(relativePath: { eq: "images/newsletter/statue-of-liberty.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
                # ...GatsbyImageSharpFluid_withWebp
                # ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        visa: file(relativePath: { eq: "images/newsletter/visa.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
                # ...GatsbyImageSharpFluid_withWebp
                # ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        passport_bag: file(relativePath: { eq: "images/newsletter/passport-bag.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
                # ...GatsbyImageSharpFluid_withWebp
                # ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `
  )
  return allImages
}